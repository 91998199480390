import React from 'react'
import styled from 'styled-components'

const CardListSection = styled.section`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  background-color: var(--color-body-dark-alt);
  position: relative;
  z-index: 1;
`

const CardList = props => {
  return <CardListSection>{props.children}</CardListSection>
}

export default CardList
