import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Container from './Container'
import media from '../styles/media'

const ReadMore = styled.span`
  font-size: 0.8rem;
  margin: 1.5rem 0 0;
  opacity: 1;
  transition: opacity 0.2s ease-out;
  color: var(--color-primary);

  ${media.tablet`
    opacity: 0;
  `};
`

const Post = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 2rem 0;
  width: 100%;
  min-height: 320px;
  background: var(--color-body-dark-alt);
  border-bottom: 1px solid #121212;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &:before {
    background: ${props =>
      props.cardImage
        ? `url(${props.cardImage.fluid.src}) center center`
        : 'var(--color-body-dark-alt)'};
    background-size: cover;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:after {
    background: rgba(0, 0, 0, 0.85);
    opacity: 0;
    transition: opacity 0.4s;
  }

  &:hover {
    &:before {
      opacity: 1;
      transition: opacity 0.2s;
      transition-delay: 0.01s;
    }

    &:after {
      opacity: 1;
      transition: all 0.1s;
    }
  }

  a {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    /* width: var(--container-max-width-medium); */
    margin: 0 auto;
    text-decoration: none;
    position: relative;
    z-index: 1;
    /* .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        padding-bottom: ${props => (props.featured ? '40%' : '60%')};
      }
    } */

    &:hover {
      ${media.tablet`
        ${ReadMore} {
          opacity: 1;
          transition: opacity 0.3s ease-in;
          transition-delay: 0.3s;
        }
      `};
    }
  }
`

const Title = styled.h2`
  font-size: 1.8rem;
  font-weight: 800;
  text-transform: capitalize;
  margin: 0 0 1.5rem;

  ${media.tablet`
    font-size: 2.2rem;
  `};
`

const Excerpt = styled.div`
  width: 100%;

  ${media.tablet`
    width: 50%;
  `};

  p {
    font-size: 0.95rem;
    line-height: 1.75;

    ${media.tablet`
      font-size: 1rem;
      line-height: 2;
    `};
  }
`

const PortfolioCard = ({
  slug,
  cardImage,
  title,
  publishDate,
  body,
  description,
  ...props
}) => {
  return (
    <Post featured={props.featured} cardImage={cardImage}>
      <Container as={Link} to={`/portfolio/${slug}/`}>
        <Title>{title}</Title>
        <Excerpt
          dangerouslySetInnerHTML={{
            __html: description.childContentfulRichText.html,
          }}
        />
        <ReadMore>Learn more about this project →</ReadMore>
      </Container>
    </Post>
  )
}

export default PortfolioCard
